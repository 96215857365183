* {
  font-family: 'Roboto', sans-serif;
}
html,body {
  min-height: 100vh;
}


/* carousel */
/* .carousel-wrapper {
  display: none;
} */


/* cart */
.cart-footer-wrapper {
  height: 50px !important;
}
.cart-close-btn {
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75)

}
/* Home:  wash-program  */
.wash-program-wrapper {
  margin-top: 30px;
}

.washprogram-header {
  margin-top: 20px;
  margin-bottom: 20px;
}
.wash-program {
  /* border : 1px solid rgb(225, 225, 225); */
  border-radius: 15px;
  box-shadow: 0 0 8px #000;
}
.washprogram-heading {
  font-size: 23px;
}
.carousel-header {
  margin-top: 20px;
  margin-bottom: 20px;
}
.carousel-heading {
  font-size: 23px;
}
.washprogram-container {
  padding: 0 30px;
  position: relative;
}
.program-header {
  padding: 10px;
}
.program-heading {
  font-size: 18px;
}
.program-details{
  padding: 8px;
}
.program-details-ul {
  color : grey;
  margin-bottom: 0;
  margin-top: 5px;
  margin-bottom: 5px;
  list-style: none;
}
.program-details-li {
  font-size: 15px;
  margin : 4px 0px;
}
.offer-applied {
  font-size: 15px;
  color: red;
  margin-left: 5px;
}
.offer-value {
  font-weight: 500;
  margin-top: 5px;
  font-size: 15px;
}
.program-sale-rate {
  font-weight: 500;
  font-size: 15px;
}
.program-rate {
  margin: 5px;
  font-size: 15px;
}
.program-btn-wrapper{
  margin-top: 16px;
  margin-bottom: 24px;
}
.program-btn {
  font-size : 15px;
}
.reviews-wrapper {
  margin-top: 30px;
}
.reviews-header {
  margin-top: 40px;
  margin-bottom: 20px;
}
.review-heading {
  font-size: 23px;
}
.reviews-cont {
  width: 98%;
  margin: 0 1%;
}
.user-review {
  border: 0;
  margin: 0;
}
.card-title {
  font-weight: 500;
  font-size: 14px;
}
.card-text {
  font-size: 14px;
}
.next-control-cont {
  position: absolute;
  height: 100px;
  width: 30px;
  top : calc((100% - 100px)/2);
  right:0;
  background-color: rgba(0,0,0,0.8);
  /* background-color: rgba(0,0,0,0.2); */
  border-radius : 3px;
}
.prev-control-cont {
  position: absolute;
  height: 100px;
  width: 30px;
  top : calc((100% - 100px)/2);
  left:0;
  background-color: rgba(0,0,0,0.8);
  /* background-color: rgba(0,0,0,0.2); */
  border-radius : 3px;
}
.social-handles-wrapper {
  padding : 0px 30px
}




/* stages  */
.accordion-button {
  font-size: 15px;
}
.add-address-inp {
  border : 1px solid grey;
}



.simpleflex {
  display: flex;
  justify-content: center;
  align-items: center;
}



/* spinner */
.spinner {
  
  border-radius: 50%;
  animation: spinner-bulqg1 0.8s infinite linear alternate,
       spinner-oaa3wk 1.6s infinite linear;
}
@keyframes spinner-bulqg1 {
  0% {
     clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
  }

  12.5% {
     clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 0%);
  }

  25% {
     clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 100%);
  }

  50% {
     clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
  }

  62.5% {
     clip-path: polygon(50% 50%, 100% 0, 100% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
  }

  75% {
     clip-path: polygon(50% 50%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 50% 100%, 0% 100%);
  }

  100% {
     clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 0% 100%);
  }
}

@keyframes spinner-oaa3wk {
  0% {
     transform: scaleY(1) rotate(0deg);
  }

  49.99% {
     transform: scaleY(1) rotate(135deg);
  }

  50% {
     transform: scaleY(-1) rotate(0deg);
  }

  100% {
     transform: scaleY(-1) rotate(-135deg);
  }
}